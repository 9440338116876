<template>
  <div class="borrow-list">
    <div class="header">
      <div class="search">
        <van-dropdown-menu>
          <van-dropdown-item class="menu-item"
                             v-model="screen.searchKey"
                             :options="searchTypeOptions" />
        </van-dropdown-menu>
        <van-search class="search-content"
                    v-model.trim="screen.searchContent"
                    @search="onSearch"
                    placeholder="请输入搜索关键词" />
        <div class="search-btn"
             @click="onSearch">搜索</div>
      </div>
      <div class="filter">
        <div class="filter-item"
             @click="vanPopupClick('status')">
          <span>{{ filter.status.label }}</span>
          <van-icon class="filter-down"
                    name="arrow-down" />
        </div>
        <div class="filter-item"
             @click="showCalendarBtn('range')">
          <span>{{ filter.time.label }}</span>
          <van-icon class="filter-down"
                    name="arrow-down" />
        </div>
      </div>

      <!-- 选择弹框 -->
      <van-popup v-model="showVanPopupType"
                 round
                 position="bottom">

        <custom-popup :title="filter.title"
                      :columns="vanPopupTypeColumns"
                      value-key="name"
                      @cancel="vanPopupCancel"
                      @confirm="vanPopupConfirm"></custom-popup>
      </van-popup>
      <!-- 选择日期 -->
      <van-calendar v-model="showCalendar"
                    :min-date="minDate"
                    :type="calendarType"
                    @close="onClose"
                    @confirm="onConfirm" />
    </div>
    <div class="main">
      <div class="list">
        <van-list :immediate-check="false"
                  v-model="listStatus.loading"
                  :finished="listStatus.finished"
                  @load="onLoad">
          <div class="list-item"
               v-for="item in list"
               :key="item.id"
               @click="itemClick(item.id)">
            <item-card class="list-item"
                       :title="item.lendingNumber"
                       :status="parseStatus(item.status)">
              <div class="content">

                <div class="content-line">
                  <span class="name">申请时间:</span>
                  <span class="value">{{ item.creationTime }}</span>
                </div>
                <div class="content-line">
                  <span class="name">领用单位:</span>
                  <span class="value">{{ item.organizationName }}</span>
                </div>
                <div class="content-line">
                  <span class="name">提货人:</span>
                  <span class="value">{{ item.pickName }}</span>
                </div>
                <div class="content-line">
                  <span class="name">联系电话:</span>
                  <span class="value">{{ item.pickMobileNumber }}</span>
                </div>
                <div class="content-line">
                  <span class="name">领取日期:</span>
                  <span class="value">{{ item.lendingStartDate }}</span>
                </div>
                <div class="content-line">
                  <span class="name">归还日期:</span>
                  <span class="value">{{ item.lendingEndDate }}</span>
                </div>
                <div class="content-line">
                  <span class="name">预计存放位置:</span>
                  <span class="value">{{ item.storagePlace }}</span>
                </div>
                <div class="content-line">
                  <span class="name">二维码:</span>
                  <span class="text-button"
                        @click.stop="getCodeBtn(item)">查看</span>
                </div>
              </div>
            </item-card>
          </div>
          <template #finished>
            <div v-if="list.length">没有更多了</div>
          </template>
        </van-list>
      </div>
      <van-empty description="暂无数据"
                 v-if="!list.length" />
    </div>
    <!-- <div class="footer">
      <van-button class="add-btn"
                  type="info"
                  @click="addBorrow"
                  round>新增</van-button>
    </div> -->

    <!-- 二维码 -->
    <van-dialog v-model="showDialog"
                title="二维码">
      <div style="text-align:center">
        <VueQr :margin='8'
               :size='280'
               :whiteMargin="true"
               :logoMargin="3"
               :logoCornerRadius="20"
               :text='codeInfo.codeText'> </VueQr>
        <div>
          <div>单号:{{codeInfo.lendingNumber}}</div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import "@/assets/less/views/assets/borrow.less";
import Filter from "ldap-filters";
import { SUCCESS_CODE } from "@/apis/notification";
import { flatMap, find } from "lodash-es";
import VueQr from "vue-qr";

export default {
  name: "CommodityList",
  data() {
    return {
      searchTypeOptions: [
        { text: "领用单号", value: "lendingNumber" },
        { text: "领用单位", value: "organizationName" },
        { text: "提货人", value: "pickName" }
      ],
      filter: {
        status: {
          id: "",
          label: "所有状态"
        },
        time: {
          id: "",
          label: "日期"
        },
        title: "所有状态"
      },
      listStatus: {
        loading: true,
        finished: false
      },
      list: [],
      showVanPopupType: false,
      showCalendar: false,
      calendarType: "",
      minDate: "",
      vanPopupTypeColumns: [],
      pager: {
        pageNumber: 1,
        pageSize: 5
      },

      screen: {
        applicantId: "APPLICANT_ID",
        usingStatus: "RECIPIENT",

        time: [],
        status: "",
        searchContent: "",
        searchKey: "lendingNumber"
      },

      codeInfo: {
        codeText: null,
        codeName: null
      },
      showDialog: false
    };
  },
  methods: {
    itemClick(id) {
      this.$router.push({
        name: "requisitionEdit",
        query: {
          id
        }
      });
    },
    addBorrow(e) {
      this.$router.push({
        name: "requisitionEdit"
      });
    },
    init() {
      this.minDate = new Date(this.$moment().add(-1, "y").format("YYYY-MM-DD"));
      this.getAssetLendings();
    },
    vanPopupClick(type) {
      if (type == "status") {
        this.filter.title = "状态";
        this.vanPopupTypeColumns = [
          { id: "", name: "全部", key: "status" },
          { id: "SUCCESS", name: "已领用", key: "status" },
          { id: "UNDER_REVIEW", name: "审核中", key: "status" },
          { id: "GIVE_BACK", name: "已归还", key: "status" },
          { id: "UNCLAIMED", name: "待领取", key: "status" },
          { id: "REJECTED", name: "已驳回", key: "status" },
          { id: "EXPIRED", name: "已过期", key: "status" }
        ];
      }
      this.showVanPopupType = true;
    },
    vanPopupCancel() {
      this.showVanPopupType = false;
    },
    vanPopupConfirm(val) {
      this.showVanPopupType = false;
      this.screen[val.key] = val.id;
      this.filter[val.key].label = val.name;
      this.filter[val.key].id = val.id;
      this.getInitialData();
    },

    showCalendarBtn(type) {
      this.showCalendar = true;
      this.calendarType = type;
    },
    onConfirm(date) {
      this.showCalendar = false;
      this.isCalendar = true;
      if (this.calendarType == "range") {
        this.screen.time = [this.$moment(date[0]).format("YYYY-MM-DD"), this.$moment(date[1]).format("YYYY-MM-DD")];
        this.filter.time.label = this.$moment(date[0]).format("YYYY-MM-DD") + "~" + this.$moment(date[1]).format("YYYY-MM-DD");
        this.filter.time.id = "";
      }
      this.getInitialData();
    },
    onClose() {
      if (!this.isCalendar) {
        this.filter.time.label = "日期";
        this.screen.time = [];
        this.getInitialData();
      }
      this.isCalendar = false;
    },

    getInitialData() {
      window.scroll({ top: 0, behavior: "instant" });
      this.listStatus.loading = true;
      this.listStatus.finished = false;
      this.pager.pageNumber = 1;
      this.getAssetLendings();
    },
    parseStatus(item) {
      if (item == "SUCCESS") return "已领用";
      if (item == "UNDER_REVIEW") return "审核中";
      if (item == "GIVE_BACK") return "已归还";
      if (item == "UNCLAIMED") return "待领取";
      if (item == "REJECTED") return "已驳回";
      if (item == "EXPIRED") return "已过期";
    },

    onSearch() {
      this.getInitialData();
    },
    handlePagination(totalPages) {
      if (this.pager.pageNumber < totalPages) {
        // 有更多数据
        this.pager.pageNumber += 1;
      } else {
        this.listStatus.finished = true;
      }
    },
    onLoad() {
      this.getAssetLendings(true);
    },
    async getAssetLendings(sign = false) {
      let params = { pageNumber: this.pager.pageNumber - 1, pageSize: this.pager.pageSize };
      params.query = this.getQuery();
      let ret = await this.$api.getAssetLendings({ params });
      // this.list = ret.data;
      if (sign) {
        this.list = this.list.concat(ret.data);
      } else {
        this.list = ret.data;
      }
      this.listStatus.loading = false;
      this.pager.totalRecords = ret.totalRecords;
      this.handlePagination(ret.totalPages);
    },
    getQuery() {
      let filters = [];
      Object.keys(this.screen).forEach(key => {
        let filter = "equalTo";
        let value = this.screen[key];
        if (key == "status") {
          if (!value) {
            filter = "approx";
            value = "CREATED";
          }
          let query = Filter.attribute(key)[filter](value);
          filters.push(query);
          return;
        }
        if (!this.screen[key] || key == "searchKey") return false;
        if (key == "searchContent" || key == "searchKey") {
          key = this.screen.searchKey;
          value = this.screen.searchContent;
          filter = "contains";
        }
        if (key == "time") {
          if (this.screen.time.length) {
            let gte = Filter.attribute("creationTime").gte(this.screen.time[0] + " 00:00:00");
            let lte = Filter.attribute("creationTime").lte(this.screen.time[1] + " 23:59:59");
            filters.push(gte);
            filters.push(lte);
          }
          return false;
        }
        let query = Filter.attribute(key)[filter](value);
        filters.push(query);
      });
      if (filters.length == 1) {
        return filters[0].toString();
      } else if (filters.length > 1) {
        return Filter.AND(filters).toString();
      }
    },
    getCodeBtn(row) {
      let codeText = row.lendingNumber + "," + row.pickMobileNumber;
      this.codeInfo = {
        codeText: codeText,
        ...row
      };
      this.showDialog = true;
    }
  },
  created() {
    this.init();
  },
  components: { VueQr },

  filters: {}
};
</script>

<style></style>
